<template>
  <div v-bind:class="[isLight ? 'background-light' : 'background-dark', 'resume-page']">

    <div class="page">
      <!-- <div class="background mdAndDown"> -->


      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-title-light' : 'body-title']"
            style="margin-bottom: 0px"
          >
            Marlon Sijnesael
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0px"
          >
            Web and game developer
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Rotterdam
          </p>
        </div>
         <div class="flex-item-right">
        <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          <v-icon v-bind:style="isLight ? 'color:	#202020' : 'color:white'"
            >mdi-phone-outline</v-icon
          >
          <a
            style="color: #db162f; font-weight: 400; padding-left: 10px"
            href="tel: +31638414198"
            >+31 638414198
          </a>
        </p>
        <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          <v-icon v-bind:style="isLight ? 'color:	#202020' : 'color:white'"
            >mdi-email-outline</v-icon
          ><a
            style="color: #db162f; font-weight: 400; padding-left: 10px"
            href="mailto:m.a.sijnesael@gmail.com"
          >
            m.a.sijnesael@gmail.com
          </a>
        </p>

          <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          <v-icon v-bind:style="isLight ? 'color:	#202020' : 'color:white'">
            mdi-download</v-icon
          ><a
            style="color: #db162f; font-weight: 400; padding-left: 10px"
            href="Marlon_Sijnesael_-_.pdf" download="Marlon_Sijnesael_-_.pdf"
          >
            Resume as .PDF
          </a>
        </p>
      </div>

    </div>
</div>


    <!-- <VideoBanner :video="'Zonder Browser/build.mp4'" :title="'Resume'"></VideoBanner> -->
    <div v-if="$store.state.mobile" class="page">
      <div class="row">
        <div class="columnLeft">
          <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">About me</h1>
          <v-divider
            v-bind:class="[isLight ? 'background-dark' : 'background-light']"
            style="margin-top: 10px; margin-bottom: 10px; width: 100%"
          ></v-divider>
        </div>
      </div>
      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0; font-weight: 400"
          >
  Hi! My name is Marlon Sijnesael.<br />
            I am 24 years old and I live in Rotterdam. I am a multidisciplinary
            developer with a passion for new technologies and finding real world
            applications for them.
            <br />
            <br />
            During my bachelor in Creative Media and Game Technologies I developed a broad
            skillset including game development, web development and quantum computing. I
            would say my ability to learn new things quickly is my greatest strength.

          </p>


        </div>
      </div>
    </div>


    <!-- <VideoBanner :video="'Zonder Browser/build.mp4'" :title="'Resume'"></VideoBanner> -->
    <div class="page">
      <div class="row">
        <div class="columnLeft">
          <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Education</h1>
          <v-divider
            v-bind:class="[isLight ? 'background-dark' : 'background-light']"
            style="margin-top: 10px; margin-bottom: 10px; width: 100%"
          ></v-divider>
        </div>
      </div>
      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            University of the Arts Utrecht
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="color: grey"
          >
            Sep 2016 - Jul 2020
          </p>
        </div>
        <div class="flex-item-right">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0; text-align: left"
          >
            Bachelor of Science, Creative Media and Game Technologies
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="color: grey; text-align: left"
          >
            Game development
          </p>
        </div>
      </div>
    </div>

    <!-- First section  -->
    <div class="page">
      <!-- <div class="background mdAndDown"> -->
      <div class="row">
        <div class="columnLeft">
          <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Experience</h1>
          <v-divider
            v-bind:class="[isLight ? 'background-dark' : 'background-light']"
            style="margin-top: 10px; margin-bottom: 10px; width: 100%"
          ></v-divider>
        </div>
      </div>
      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Unity / Serious games Developer
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            ING - Tech Research and Development
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="color: grey"
          >
            Jul 2019 - Jan 2020
          </p>
        </div>
        <div class="flex-item-right">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text', 'col-right']"
            style="margin-bottom: 50px; text-align: left"
          >
            At ING I was part of the quantum team responsible for making games and apps
            used to teach people the fundamentals of quantum mechanics / computing. During
            this time I created several VR games and a learning app, which we presented to
            stakeholders like IBM and showed at Devoxx in Antwerp.
          </p>
        </div>
      </div>

      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Freelance web / game development
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="color: grey"
          >
            Jan 2019 - present day
          </p>
        </div>
        <div class="flex-item-right">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text', 'col-right']"
            style="margin-bottom: 0; padding-right: 26px; text-align: left"
          >
            During my bachelor and internship I registered myself as a freelancer. In the
            past two years I have successfully completed multiple freelance jobs ranging
            from web/app development to game development.
          </p>
        </div>
      </div>
      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Unity / Serious games Developer - internship
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            ING - Tech Research and Development
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="color: grey"
          >
            Jan 2019 - jul 2019
          </p>
        </div>
        <div class="flex-item-right">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text', 'col-right']"
            style="margin-bottom: 0; padding-right: 26px; text-align: left"
          >
            During the first month of my internship I worked on finishing an existing VR
            kids game. After that I transfered to the Quantum team where I worked on
            games used to demystify quantum mechanics.
          </p>
        </div>
      </div>
      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Python and Arduino teaching assistent
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            University of the Arts Utrecht
          </p>
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="color: grey"
          >
            Sep 2017 - Jan 2018 / Sep 2018 - Jan 2019
          </p>
        </div>
        <div class="flex-item-right">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0; padding-right: 26px; text-align: left"
          >
            As a teaching assistent I was responsible for helping out during practical
            lessons, provide extra lessons and check and grade the students' homework.
          </p>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="page">
      <div class="row">
        <div class="columnLeft">
          <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">Skills</h1>
          <v-divider
            v-bind:class="[isLight ? 'background-dark' : 'background-light']"
            style="margin-top: 10px; margin-bottom: 10px; width: 100%"
          ></v-divider>
        </div>
      </div>
      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Tools
          </p>
        </div>
         <div class="flex-item-right">
        <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          C# / Python / PHP / JavaScript / HTML5 / CSS / SQL / Qiskit
        </p>
      </div>
           </div>
         <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            3D engines / libraries
          </p>
        </div>
          <div class="flex-item-right">
        <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          Unity3D / Babylon.js / Three.js / A-frame
        </p>
      </div>
        </div>
         <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Database
          </p>
        </div>
          <div class="flex-item-right">
        <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          SQL / Firebase / Mongodb
        </p>
      </div>
          </div>
          <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Software
          </p>
        </div>
          <div class="flex-item-right">
        <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          Photoshop / Illustrator / Premiere / blender
        </p>
      </div>
      </div>
         <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Other skills
          </p>
        </div>
         <div class="flex-item-right">
        <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          VR & AR development / Arduino / Raspberry Pi / Game design / PWA
        </p>
      </div>
      </div>
           <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            Languages
          </p>
        </div>
         <div class="flex-item-right">
        <p
          v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
          style="margin-bottom: 0; text-align: left"
        >
          Dutch / English
        </p>
         </div>
      </div>
    </div>

    <!-- First section  -->
    <div class="page">
      <!-- <div class="background mdAndDown"> -->
      <div class="row">
        <div class="columnLeft">
          <h1 v-bind:class="[isLight ? 'body-title-light' : 'body-title']">
            Recognition
          </h1>
          <v-divider
            v-bind:class="[isLight ? 'background-dark' : 'background-light']"
            style="margin-top: 10px; margin-bottom: 10px; width: 100%"
          ></v-divider>
        </div>
      </div>

      <div class="flex-container">
        <div class="flex-item-left">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0"
          >
            HKU Award for Innovation
          </p>
        </div>
        <div class="flex-item-right">
          <p
            v-bind:class="[isLight ? 'body-text-light' : 'body-text']"
            style="margin-bottom: 0; text-align: left"
          >
            <a style="color: #db162f; font-weight: 400"> Contour</a> won the
            2020 HKU award for innovation. It was selected from a group of 20 other
            nominated projects and over 500 graduation projects.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Resume",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
    this.$forceUpdate();
  },
  methods: {},
  computed: {
    isLight() {
      let light = this.$store.state.viewMode === "light";
      if (localStorage.getItem("mode") === "light") light = true;
      else light = false;
      return light;
    },
  },
  data() {
    return {};
  },
};
//   {left: "Creating visualisations", center: "Viewing visualisations", right: ""},
</script>

<style lang="scss">
.col-right {
  padding-right: 16px;
}

.text-black {
  color: #202020;
  font-weight: 500;
}
.resume-page {
}
.column-tight {
  float: left;
  width: 50%;
  text-align: left;
  padding: 0 0 40px 40px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
   padding: 0 0 0px 32px;
}

.flex-item-left {
  padding: 10px;
  flex: 50%;
  text-align: left;

}

.flex-item-right {
  padding: 10px;
  flex: 50%;
  text-align: left;
}

/* Clear floats after the columns */
.row-tight:after {
  content: "";
  display: table;
  clear: both;
}

.background-dark {
  background-color: #202020;
}
.background-light {
  background-color: #f3f3f3;
}
.contourPage {
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100vw;
  background-color: #f3f3f3;
  z-index: 1;
  color: white;
  overflow-x: hidden;
}

.content {
  margin-bottom: 10px;
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  max-width: 1250px;
}
@media only screen and (max-width: 900px) {
  .flex-container {
    flex-direction: column;
  }
  .flex-container {
   padding: 0 0 0px 0px;
}
.flex-item-left > .body-text-light{
    padding-bottom: 0
}
.flex-item-right {
    padding-left: 32px;
    padding-bottom: 48px;
}
  .page:nth-child(1) {
    margin-top: 20%;
  }
  .page {
    width: 100vw;
    padding: 4px;
    margin-bottom: 24px;
    border-radius: 50px;
  }
  .background {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .flex-item-left p:nth-of-type(1){
    font-weight: 600;
  }
  .section {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
  }
  .columnLeft {
    padding-bottom: 20px;
    padding-top: 24px;
  }

  .columnRight {
    padding-bottom: 32px;
    padding-top: 0;
  }
}
</style>
